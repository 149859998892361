<template>
  <div
    class="buzzers page"
    :class="{ 'mobile': device.mobile }"
  >
    <v-sheet 
      :width="breakpoint('xs') && !input.focus ? '360px' : '100%'"
      class="buzzers-content px-2 transparent"
    >
      <v-window
        v-model="content"
        class="levels"
      >
        <v-window-item
          value="list"
          class="level"
        >
          <div
            class="header pt-4"
          >
            <!-- <v-row>
              <v-col class="d-flex"> -->
            <v-combobox
              v-model="input.search.value"
              :loading="input.search.loading"
              :disabled="input.search.disabled"
              :error-messages="input.search.messages"
              :prepend-inner-icon="icons.mdiMagnify"
              :label="input.search.label"
              :menu-props="{ closeOnContentClick: true }"
              hide-details
              deletable-chips
              hide-selected
              chips
              small-chips
              multiple
              clearable
              solo
              flat
              name="buzzer-search"
              ref="search"
              @keyup.esc="input.search.value=null"
            />
              <!-- </v-col>
              <v-col
                class="d-flex"
                cols="4"
              >
                <v-select
                  v-model="input.filter.value"
                  :items="input.filter.items"
                  :label="input.filter.label"
                  clearable
                />
              </v-col>
            </v-row> -->
          </div>

          <v-list
            class="buzzer-list list align-self-stretch rounded mt-2"
            dense
          >
            <v-list-item-group
              v-for="list in groups"
              :key="list.label"
              v-model="selectedItem"
              class="pa-0 pb-2"
              color="primary"
            >
              <v-subheader 
                class="px-4"
              >
                <v-badge
                  v-if="list.items.length"
                  color="grey lighten-1"
                  inline
                  :content="list.items.length"
                >
                  {{ list.label }}
                </v-badge>
                <span v-else>
                  {{ list.label }} –
                </span>
              </v-subheader>
              <v-list-item 
                v-for="(buzzer, name) in list.items"
                :key="buzzer.code"
                :class="buzzerRef(name)"
                :value="buzzer.code"
                class="item"
                @click="selectBuzzer(buzzer.code)"
              >
                <v-list-item-icon class="mr-4">
                  <v-progress-circular
                    v-if="buzzer.loading"
                    indeterminate
                    :size="24"
                    :width="3"
                    color="primary"
                  />
                  <v-icon 
                    v-else
                    v-text="buzzerIcon(buzzer)"
                    :color="statusLight(buzzer)"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ formatBuzzerCode(buzzer.code) }}</v-list-item-title>
                  <!-- <v-list-item-subtitle>{{ item.brand.title }}</v-list-item-subtitle> -->
                </v-list-item-content>
                <v-list-item-action class="mr-2">
                  <v-list-item-action-text>{{ buzzer.mode }}</v-list-item-action-text>
                </v-list-item-action>  
              </v-list-item>
            </v-list-item-group>

            <v-expand-transition mode="out-in">
              <div
                v-show="showPlaceholder"
                class="placeholder"
              >
                <v-skeleton-loader
                  v-for="i in 4"
                  :key="i"
                  ref="placeholder"
                  type="list-item-two-line"
                  :tile="false"
                  class="mx-4 img"
                />
              </div>
            </v-expand-transition>
          </v-list>
        </v-window-item>

        <v-window-item
          value="item"
          class="level"
        >
          <buzzer 
            v-if="selected!=null"
            ref="buzzer"
          />
        </v-window-item>
      </v-window>
    </v-sheet>
    <div 
      class="map-view pl-0 d-flex justify-center align-center rounded"
    >
      <map-comp
        v-if="map.toggle"
        :buzzers="mapBuzzers"
        :options="map.options"
        ref="map"
      />
      <v-btn
        v-else
        text
        color="secondary"
        @click="toggleMap(true)"
      >
        <v-icon left>{{ icons.mdiMapMarker }}</v-icon>
        Ver mapa
      </v-btn>
      <v-card 
        light
        class="live-controls"
        :class="{ toggled: input.alert.toggle }"
      >
        <v-btn 
          tile
          small
          light
          :disabled="btnTimer.disabled"
          color="white"
          class="refresh-btn grey--text text--darken-2 elevation-0"
          @click="input.alert.toggle=!input.alert.toggle"
        >
          <v-progress-circular
            :value="timerProgress"
            :rotate="-90"
            size="16"
            width="2"
            class="ml-n1"
            style="margin-right: 10px;"
            color="primary"
          />
          Tempo real ({{ timer.interval }}s)
          <v-icon
            v-if="input.alert.active"
            small
            color="warning"
            class="ml-2"
          >
            {{ icons.alertOn }}
          </v-icon>
        </v-btn>
        <v-expand-transition>
          <div
            v-show="input.alert.toggle"
            class="pt-1 pa-4"
          >
            <v-switch
              v-model="input.alert.impression"
              inset
              dense
              :label="input.alert.impression ? 'Impressão' : 'Status'"
              color="primary"
            />
            <v-switch
              v-model="input.alert.active"
              inset
              dense
              :label="'Alerta '+(input.alert.active ? 'ligado' : 'desligado')"
              color="warning"
            />
            <v-divider />
            <v-slider
              v-model="timer.interval"
              step="5"
              ticks="always"
              :max="30"
              :min="5"
              :prepend-icon="icons.mdiTimerOutline"
              hide-details
              class="pt-2"
            />
          </div>
        </v-expand-transition>
      </v-card>
    </div>
  </div>
</template>

<style type="text/css">

  .buzzers {
    position: relative;
  }

  .page .map-view {
    position: fixed;
    top: 56px;
    right: 0;
    left: auto;
    bottom: 0;
    padding: 16px;
    width: calc(100vw - 360px);
    height: calc(100vh - 56px);
    /* z-index: 1; */
  }
  @media (orientation: portrait) {
    .page.mobile .buzzers-content {
      display: block;
    }
    .page.mobile .map-view {
      display: none;
    }
  }
  @media (orientation: landscape) {
    .page.mobile .map-view {
      top: 0;
      padding: 0;
      width: 100vw;
      height: 100vh;
      border-radius: 0;
    }
    .page.mobile .buzzers-content {
      display: none;
    }
  }

  @media (min-width: 960px) {
    .page .map-view {
      top: 64px;
      height: calc(100vh - 64px);
    }
  }

  .map-view .live-controls {
    position: absolute;
    top: 1.5rem;
    left: .5rem;
    z-index: 11;
    opacity: .85;
  }
  .map-view .live-controls.toggled {
    opacity: 1;
  }


  /* .window .card .buzzers .form-content, .window .card .buzzer .form-content {
    max-height: calc(64vh - 64px);
  } */

</style>

<script>
  // Icons
  import { mdiCog, mdiMagnify, mdiContactlessPaymentCircle as mdiBuzzer, mdiAlertCircle, mdiCircleMedium, mdiMapMarkerOff, mdiMapMarker, mdiAlertCircleCheck } from '@mdi/js';

  // Utilities
  import services from '@/services'
  import { sync } from 'vuex-pathify'
  import { mask } from 'vue-the-mask'
  import device from 'mobile-device-detect';
  import visibility from 'vue-visibility-change';
  import _ from 'lodash';
  var moment = require('moment');

  export default {
    name: 'Buzzers',
    metaInfo: {
      title: 'Buzzers'
    },

    components: {
      Buzzer: () => import('@/views/buzzers/Buzzer'),
      MapComp: () => import('@/components/mMap'),
    },

    data: () => ({
      icons: {
        mdiCog,
        mdiMagnify,
        mdiBuzzer,
        mdiAlertCircle,
        mdiCircleMedium,
        mdiMapMarkerOff,
        mdiMapMarker,
        alertOn: mdiAlertCircleCheck
      },
      item: null,
      content: 'list',
      input: {
        search: {
          value: [],
          input: '',
          label: 'Buscar',
          loading: false,
          disabled: false,
          messages: [],
          items: [
            { 
              text: 'nr:',
              value: 'control_number',
              items: []
            },
            { 
              text: 'código:',
              value: 'code',
              items: []
            },
            {
              text: 'parceiro:',
              value: 'driver.name',
              items: []
            },
            {
              text: 'placa:',
              value: 'driver.name',
              items: []
            },
            { 
              text: 'modo:',
              value: 'mode',
              items: ['ope', 'tst', 'mnt']
            },
            { 
              text: 'status:',
              value: 'status',
              items: ['on', 'away', 'off']
            },
          ],
        },
        filter: {
          value: null,
          items: [
            'ON',
            'AWAY',
            'OFF',
          ],
          label: 'Status',
          loading: false,
          disabled: false,
          messages: []
        },
        alert: {
          toggle: false,
          active: false,
          impression: false
        }
      },
      device: {
        mobile: device.isMobileOnly,
      },
      btnTimer: {
        disabled: false,
        loading: false
      },
      timer: {
        on: true,
        count: 0,
        controller: null,
        interval: 30
      },
    }),

    computed: {
      views: sync('app/views'),
      loading: sync('app/views@loading'),
      view: sync('app/views@buzzers'),
      main: sync('app/views@main'),
      map: sync('app/views@map'),
      items: sync('buzzers/data@items'),
      filter: sync('buzzers/data@filter'),
      selected: sync('buzzers/data@selected'),
      prototype: sync('buzzers/prototype'),
      user: sync('user/data'),
      toast: sync('app/toast'),

      selectedItem: {
        get () {
          return this.selected;
        },
        set (buzzer) {
          // this.selected = buzzer;
        }
      },

      groups () {
        let groups = _.groupBy(this.searchItems, 'mode');
        const OPE = _.partition(groups['OPE'], (buzzer) => { return buzzer.driver.name!=null; });
        groups['parceiros'] = OPE[0];
        groups['OPE'] = OPE[1];
        return [
          { label: 'PARCEIROS', items: _.has(groups, 'parceiros') ? groups.parceiros : [] },
          { label: 'OPE', items: _.has(groups, 'OPE') ? groups.OPE : [] },
          { label: 'TST', items: _.has(groups, 'TST') ? groups.TST : [] },
          { label: 'MNT', items: _.has(groups, 'MNT') ? groups.MNT : [] },
        ]
      },

      mapBuzzers () {
        const buzzers = this.filteredItems;
        const toggle = _.size(buzzers) > 0 ? true : false;
        return {
          toggle: toggle,
          data: buzzers,
          selected: this.selected,
          alert: this.input.alert.active,
          impression: this.input.alert.impression,
        }
      },

      filteredItems () {
        let search = this.input.search.value;
        search = _.isNil(search) ? false : search;
        let status = this.input.filter.value;
        status = _.isNil(status) ? false : status;
        const filter = typeof search=='string' || typeof status=='string';
        // console.log(filter, search, status);
        return status ? _.pickBy(search!='' && search!=null ? this.searchItems : this.items, (item) => {
          const light = this.statusLight(item);
          return status == 'AWAY' ? light == 'warning' : item.status == status;
        }) : search ? this.searchItems : this.items;
      },

      searchItems () {
        let keywords = this.input.search.value;
        let field = _.find(this.input.search.value, v => typeof v == 'object');
        field = typeof field != 'undefined' ? field.value : null;
        const items = this.searchEngine(this.items, keywords, field, true);
        console.log(items);
        return !_.isEmpty(items) ? items : this.searchEngine(this.items, keywords, field);
      },

      searchOptions () {
        const selection = _.find(this.input.search.value, v => typeof v == 'object');
        const items = _.isNil(selection) || selection.length==0 ? this.input.search.items : _.has(selection, 'items') ? selection.items : [];
        return items;
      },

      timerProgress () {
        return (this.timer.count/this.timer.interval)*100;
      },

      showPlaceholder () {
        return Object.keys(this.items).length==0 && this.view.loading;
      }
    },

    watch: {
      $route (to, from) {
        this.updateView(to);
      },

      'input.search.value': function () {
        this.updateFilter();
      },
      'input.filter.value': function () {
        this.updateFilter();
      },
    },

    methods: {
      ...services,

      updateFilter () {
        // let s = this.input.search.value;
        // s = s ? s : '';
        // let f = this.input.filter.value;
        // f = f ? f : '';
        // let space = s=='' || f=='' ? '' : ' ';
        // let filter = s + space + f;
        // filter = filter.length > 0 && f.length > 0 ? filter + ' ' + f : '';
        this.filter = {
          status: this.input.filter.value,
          search: this.input.search.value
        }
      },

      updateView (route) {
        // set content
        const buzzer = this.getParam(route, 'buzzer');
        this.selected = buzzer;

        if (buzzer) {
          this.content = 'item';
          this.searchBuzzer(buzzer);
        }else{
          this.content = 'list';
          this.selectBuzzer(null);
          const filter = this.getQuery(route, 'filter');
          if (!!filter) {
            this.input.search.value = _.split(filter, ',');
          }
        }
        this.getTracking();
      },

      selectBuzzer (buzzer) {
        _.map(this.items, (i) => {
          // TODO cancel other requests
          i.loading = false;
        });
        if (buzzer) {
          this.items[buzzer].loading = true;
          this.map.panned = false;

          // if (this.$route.path.indexOf('')) {
            setTimeout(() => {
              this.$router.push({ path: `/buzzers/${buzzer}` });
            }, 250);
          // }
        }
      },

      searchEngine (items, keywords, field, exact) {
        return _.pickBy(items, (item) => {
          return keywords == '' ? true : _.some(_.map(keywords, (k) => {
            field = k.indexOf('#')>=0 ? 'control_number' : field;
            k = field=='control_number' ? k.replace('#', '') : k;
            const match = this.matchKey(k, item, field, exact);
            return match;
          }), (b) => {
            return b;
          });
        });
      },

      matchKey (k, item, field, exact) {
        exact = _.isNil(exact) ? false : exact;
        const key = (_.isNil(field) || field=='code') || !exact ? new RegExp(k, 'i') : new RegExp(`^${k}$`, 'gi');
        const match = _.isNil(_.get(item, field)) ? null : _.get(item, field).match(key);
        if (!_.isNil(match)) console.log(key, _.get(item, field), match);
        return !_.isNil(field) ? !_.isNil(match) : key.test(item.control_number) || key.test(item.code) || key.test(item.mode) || key.test(item.status) || key.test(item.driver.name) || key.test(item.driver.cpf) || key.test(item.driver.plate);
      },

      toggleMap (b) {
        this.map.toggle = true;
        this.setTimer(true);
      },

      buzzerRef (i) {
        return 'buzzer-'+i;
      },

      listBy (key, value) {
        const list = _.filter(this.sortBy(this.filteredItems, ['mode', 'code']), function(item){
          return _.get(item, key)==value;
        });
        const c = list.length;
        return {
          items: list,
          count: c
        };
      },

      sortBy (items, fields) {
        return _.sortBy(items, fields);
      },

      // sortByDate (order) {
        //   return _.orderBy(this.items, [function(i) { 
          //     return moment(i.impression.updated).format('YYYY-MM-DD HH:mm:ss');
      //   }],[order]);
      // },

      buzzerIcon (buzzer) {
        let icon;
        if (this.input.alert.active) {
          if (buzzer.mode=='OPE') {
            icon = this.statusLight(buzzer) == 'success' ? this.icons.mdiBuzzer : buzzer.status == 'ON' ? this.icons.mdiAlertCircle : this.icons.mdiCircleMedium;
          }else{
            icon = buzzer.status == 'ON' ? buzzer.impression.tracked&&buzzer.impression.updated ? this.icons.mdiBuzzer : this.icons.mdiMapMarkerOff : this.icons.mdiCircleMedium;
          }
        }else{
          icon = this.icons.mdiCircleMedium;
        }
        return icon;
      },

      statusLight (buzzer) {
        let color = 'grey';
        if (this.input.alert.active) {
          const updated = buzzer.impression.updated;
          const tracked = buzzer.impression.tracked;
          let diff;
          if (buzzer.status=='ON') {
            if (updated&&tracked) {
              const diffTrack = moment(updated).diff(tracked,'minutes');
              const diffImpression = moment().diff(updated,'minutes'); 
              diff = diffImpression;
              if (diff<=15) {
                color = 'success';
              }else{
                color = 'warning';
              }
            }else{
              color = 'grey';
            }
          }else{
            color = 'error';
          }
        }else{
          color = 'primary';
        }
        
        return color;
      },

      translateNotation(field) {
        return this.dictionary[field];
      },

      getTracking () {
        this.map.loading = true;

        this.timerUpdate(false);

        const username = this.user.username;
        const token = this.user.auth.token;

          this.$api.COM
            .get('/getlistdevicestrackadmin/'+username+'/'+token+'/?format=json')
            .then(response => {
              console.log('getTracking => ',response);
              if(response.data.retorno==200){
                // show markers
                let items = {};
                for (let i in response.data.data) {
                  const buzzer = response.data.data[i];
                  const proto = _.has(this.items, buzzer.cod_device) ? _.cloneDeep(this.items[buzzer.cod_device]) : this.prototype;
                  items[buzzer.cod_device] = _.merge(_.cloneDeep(proto), {
                    code: buzzer.cod_device,
                    status: buzzer.status_device,
                    mode: buzzer.modo_device,
                    control_number: buzzer.nr_patrimonio,
                    driver: {
                      id: buzzer.id_motorista,
                      cpf: buzzer.cpf_motorista,
                      name: buzzer.nm_motorista, 
                      plate: buzzer.placa, 
                    },
                    position: {
                      lat: buzzer.lat,
                      lng: buzzer.log,
                    },
                    updated: buzzer.dt_status !== null ? moment.utc(buzzer.dt_status,'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DD HH:mm:ss') : null,
                    impression: {
                      position: {
                        lat: buzzer.lat_ultima_impressao,
                        lng: buzzer.log_ultima_impressao,
                      },
                      audience: buzzer.total_kmt_est,
                      updated: buzzer.dt_impressao !== null ? moment.utc(buzzer.dt_impressao,'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DD HH:mm:ss') : null,
                      file: buzzer.nm_anuncio,
                      id: buzzer.id_anuncio,
                    },
                  });
                }
                // Remove old cached items
                _.map(_.difference(_.keys(this.items), _.keys(items)), (key) => {
                  this.$delete(this.items, key);
                });

                if (this.map.toggle) this.setTimer();

                this.items = Object.assign({}, this.items, items);

                if (this.getParam(this.$route, 'buzzer')&&this.content=='item'&&_.has(this.$refs, 'buzzer')) {
                  this.$refs.buzzer.updateView();
                }

              }else if(response.data.retorno==404){
                this.handleError(response.data.retorno, 'Dados de tracking não encontrados.');

              }else if(response.data.retorno==401){
                this.getout();
                this.handleError(response.data.retorno, 'Sua sessão expirou...');
              }else{
                this.handleError(response.data.retorno, 'Erro desconhecido. Vamos investigar o que houve! 😊');
              }
            })
            .catch(error => {
              this.handleError(error);
            })
            .finally(() => {
              this.map.loading = false;
            });
      },

      searchBuzzer (buzzer) {
        const search = this.isValid(buzzer) ? buzzer : this.rawData(this.input.search.value);
        const username = this.user.username;
        const token = this.user.auth.token;

        this.$api.COM
          .get('/getdeviceinfobycodigoadmin/'+username+'/'+token+'/'+buzzer+'/?format=json')
          .then(response => {
            console.log('getdeviceinfobycodigoadmin => ',response);
            if(response.data.retorno==200){
              this.items[buzzer] = Object.assign({}, this.items[buzzer], {
                status: response.data.statusDisplay, 
                mode: response.data.modeOperacao,
                id: response.data.id_device,
                control_number: response.data.nr_patrimonio,
                up_time: response.data.up_time,
                controller: {
                  mcu: response.data.mcu,
                  os: response.data.so,
                  db: response.data.bd,
                  app: response.data.app,
                  ram_tot: response.data.ram_tot,
                  disk_tot: response.data.disk_tot,
                },
                other: {
                  tk_acesso: response.data.tk_acesso,
                  cod_acesso: response.data.cod_acesso,
                  code_version_owner: response.data.code_version_owner,
                  code_version_main: response.data.code_version_main,
                },
                warnings: {
                  code: response.data.warning_code,
                  messages: response.data.warning_messages,
                  notifiedAt: response.data.email_alerta_enviado !== null ? moment.utc(response.data.email_alerta_enviado,'YYYY-MM-DD HH:mm:ss').local() : null,
                },
                display: {
                  pixel_pitch: response.data.pixel_pitch,
                  chipset: response.data.chipset,
                  rgb_solution: response.data.rgb_solution
                },
                driver: {
                  id: response.data.id_motorista,
                  cpf: response.data.cpf_motorista,
                  name: response.data.nm_motorista,
                  photo: response.data.foto_motorista,
                  plate: buzzer.placa,
                },
                network: {
                  ssid: response.data.ssid,
                  password: response.data.ssid_pwd,
                  ip_local: response.data.ip_local,
                  ip_publico: response.data.ip_publico
                },
                changed: [],
                loading: false,
              });

              this.map.panned = false;

              if (this.getParam(this.$route, 'buzzer')&&this.content=='item'&&_.has(this.$refs, 'buzzer')) {
                this.$refs.buzzer.updateView();
              }

            }else if(response.data.retorno==404){
              this.input.search.messages = ['Buzzer não encontrado'];
            }else if(response.data.retorno==401){
              this.getout();
              this.handleError(response.data.retorno, 'Sua sessão expirou...');
            }else{
              this.handleError(response.data.retorno, 'Erro desconhecido. Vamos investigar o que houve! 😊');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
          });
      },

      updateNow () {
        // clearTimeout(this.view.timer.controller);
        this.timer.count = this.timer.interval;
        this.timerUpdate(true);
      },
      
      timerUpdate (toggle) {
        toggle = typeof toggle == 'undefined' ? true : toggle;

        if (toggle) {
          if (this.timer.on) {
            if (this.timer.controller==null) {
              this.setTimer();
            }else{
              // update timer props
              this.timer.count += 1;
              if (this.timer.count>this.timer.interval) {
                if (!visibility.hidden()) {
                  this.getTracking();
                  this.timer.on = false;
                  clearInterval(this.timer.controller);
                }
                this.timer.count = 0;
              }
            }
          }
        }else{
          this.timer.count = 0;
          this.timer.on = false;
          clearInterval(this.timer.controller);
        }
      },

      setTimer () {
        this.timer.on = true;
        this.timer.controller = setInterval(this.timerUpdate, 1000);
      },
    },

    mounted () {
      this.filter.status = null;
      this.filter.search = null;
      this.updateView(this.$route);
      this.map.toggle = false;
    },

    beforeDestroy () {
      // this.filter = null;
      this.timer.count = 0;
      this.timer.on = false;
      clearInterval(this.timer.controller);
    },

    directives: {
      mask,
    },

  }

</script>
