<template>
  <v-dialog 
    v-model="toggle"
    :max-width="size"
    transition="slide-y-reverse-transition"
    @click:outside="closeView"
    @keydown.esc="closeView"
    :fullscreen="breakpoint(null, 'xs')"
  >
    <v-sheet 
      class="driver card-content d-flex flex-column"
      :max-height="breakpoint(null, 'xs') ? '100vh' : '90vh'"
    >
      <v-progress-linear
        v-if="loadingView"
        indeterminate
        absolute
        color="primary"
      />
      <v-fab-transition>
        <v-btn
          text
          icon
          :absolute="breakpoint('xs')"
          :fixed="breakpoint(null, 'xs')"
          fab
          small
          class="close-btn elevation-2"
          @click="closeView()"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-fab-transition>

      <v-card-text 
        v-if="toggle&&ready"
        class="scrollable "
      >
        <div class="profile-header text-center pa-4">
          <div class="profile-img">
            <v-avatar 
              v-show="!toggleUpload"
              :size="photoToggle ? '100%' : '96'"
              :rounded="photoToggle"
            >
              <img
                v-if="profile.photo!==null"
                :src="profile.photo"
                style="cursor: pointer;"
                @click="photoToggle = !photoToggle;"
              >
              <icon-base
                v-else
                width="96"
                height="96"
                icon-name="avatar"
                class="avatar-default"
              >
                <avatar-default />
              </icon-base>
              <v-fab-transition>
                <v-btn
                  v-show="!toggleUpload"
                  class="btn-upload"
                  color="white"
                  fab
                  light
                  small 
                  absolute
                  bottom
                  right
                  elevation="1"
                  @click="toggleCropper(true);"
                >
                  <v-icon>{{ icons.mdiPencil }}</v-icon>
                </v-btn>
              </v-fab-transition>
            </v-avatar>

            <v-btn
              v-show="toggleUpload"
              class="btn-rotate"
              color="primary"
              fab
              light
              small
              absolute
              bottom
              left
              elevation="1"
              @click="cropper.rotate(-1)"
            >
              <v-icon>{{ icons.mdiRotateLeft }}</v-icon>
            </v-btn>

            <cropper
              v-show="toggleUpload"
              v-model="cropper"
              ref="cropper"
              :width="240"
              :height="240"
              :quality="1"
              :prevent-white-space="true"
              :zoom-speed="10"
              :disable-click-to-choose="true"
              :show-remove-button="false"
              :file-size-limit="10000000"
              data-exif-orientation="1"
              placeholder=""
              @init="handleCropperInit"
              @new-image-drawn="handleNewImage"
              @file-type-mismatch="onFileTypeMismatch"
              @file-size-exceed="onFileSizeExceed"
            />

            <v-btn
              v-show="toggleUpload"
              class="btn-ok"
              color="secondary"
              fab
              light
              small
              absolute
              bottom
              right
              elevation="1"
              @click="prepareImgUpload();"
            >
              <v-icon>{{ icons.mdiCheck }}</v-icon>
            </v-btn>
            <v-btn
              v-show="toggleUpload"
              class="btn-cancel"
              fab
              dark
              small
              absolute
              bottom
              left
              elevation="1"
              @click="toggleCropper(false);"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </div>

          <div class="title text--darken-1 mt-6 mb-2">
            {{ profile.fullname }}
          </div>
          <div 
            v-if="profile.status=='ACT'"
            class="text-caption text--disabled mb-2"
          >
            Parceiro desde {{ profile.status.changedAt }}
          </div>
          <div class="text-caption text--disabled mb-2">
            Cadastrou-se em {{ profile.events.created }}
          </div>
          <v-rating
            v-model="profile.rating"
            hover
            class="mb-2"
            @input="setRating"
          >
            <template v-slot:item="props">
              <v-icon
                :color="props.isFilled ? fields.rating.colors[profile.rating-1] : 'grey lighten-2'"
                size="40"
                @click="props.click"
              >
                {{ props.isFilled ? icons.mdiStar : icons.mdiStarOutline }}
              </v-icon>
            </template>
          </v-rating>
          <div 
            class="tags control mt-2 mx-auto"
            @click="toggleTags(true)"
          >
            <v-expand-transition>
              <v-combobox
                v-if="fields.tags.toggle"
                v-model="profile.tags"
                :items="tags"
                :loading="fields.tags.loading"
                :disabled="fields.tags.disabled"
                :label="fields.tags.label"
                menu-props="bottom, offsetY"
                multiple
                hide-no-data
                hide-details
                outlined
                chips
                small-chips
                deletable-chips
                color="primary"
                ref="tags"
                class=""
                @blur="toggleTags(false)"
                @change="profileChanged('tags')"
              />
              <v-card
                v-else
                flat
                class="text-center"
              >
                <span 
                  v-show="profile.tags.length==0"
                  class="text-overline primary--text"
                >Tags</span>
                <v-chip
                  v-for="(tag) in profile.tags"
                  :key="'tag-'+tag"
                  small
                  color="primary"
                  class="ma-1"
                >
                  {{ tag }}
                </v-chip>
                <v-btn
                  icon
                  small
                  color="primary"
                >
                  <v-icon small>{{ icons.mdiPencil }}</v-icon>
                </v-btn>
              </v-card>
            </v-expand-transition>
          </div>
          <div class="header-controls mx-0 mt-6 d-flex flex-column justify-center">
            <v-expand-transition>
              <v-card class="status mx-auto d-flex">
                <v-menu
                  transition="slide-y-transition"
                  right
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      tile
                      width="48"
                      height="48"
                      v-on="on"
                      @click="getStatusHistory"
                    >
                      <v-icon>{{ icons.mdiTransitConnectionHorizontal }}</v-icon>
                    </v-btn>
                  </template>
                  <v-card 
                    flat
                    :loading="controller.status.loading"
                  >
                    <v-subheader>
                      Histórico:
                    </v-subheader>
                    <v-list 
                      subheader
                      max-height="25vh"
                      class="scrollable"
                    >
                      <v-divider />
                      <v-list-item
                        v-for="(item, i) in controller.status.history"
                        :key="'status-history-'+i+'-'+item.value"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-medium">{{ item.text }}</v-list-item-title>
                          <v-list-item-subtitle class="text-caption">{{ item.timestamp | brDate }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
                <v-divider vertical class="mr-1" />
                <v-select
                  v-model="profile.status.value"
                  :items="allowedStatus"
                  :loading="fields.status.loading"
                  :disabled="fields.status.disabled"
                  :label="fields.status.label"
                  menu-props="auto, light"
                  hide-details
                  solo
                  flat
                  color="primary"
                  class="rounded-l-0"
                  @change="setStatus()"
                />
              </v-card>
            </v-expand-transition>

            <v-select
              v-model="profile.fleet"
              :items="fleets"
              :loading="fields.fleet.loading"
              :disabled="fields.fleet.disabled"
              :label="fields.fleet.label"
              item-value="id_fleet"
              item-text="name"
              menu-props="auto, light"
              height="48"
              solo
              hide-details
              color="primary"
              class="fleet mx-auto mt-4"
              @change="profileChanged('fleet')"
            />

            <v-expand-transition mode="out-in">
              <div 
                v-if="codeReader.toggle"
                class="reader mt-4"
              >
                <qr-code-reader 
                  ref="codeReader"
                  @response="codeResponse"
                />
                <v-text-field
                  v-model="fields.buzzer.value"
                  v-mask="fields.buzzer.mask"
                  :loading="fields.buzzer.loading"
                  :disabled="fields.buzzer.disabled"
                  :label="fields.buzzer.label"
                  :append-icon="icons.mdiCheck"
                  @keyup.enter="setPartner(fields.buzzer.value)"
                  @click:append="setPartner(fields.buzzer.value)"
                  hide-details
                  solo
                  dense
                  class="control mx-2 mb-2"
                  style="margin-top: -44px;"
                />
                <v-btn 
                  absolute
                  fab
                  small
                  dark
                  :disabled="partnerBtn.disabled"
                  class="close-btn"
                  style="opacity: .5"
                  @click="toggleCodeReader()"
                >
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
              </div>

              <v-card
                v-else-if="profile.status.value=='REA'||profile.status.value=='SCH'||profile.status.value=='ACT'"
                max-width="20rem"
                class="buzzer mx-auto my-4"
                @click="getBuzzer"
              >
                <v-btn 
                  v-if="!profile.partner&&(profile.status.value=='REA'||profile.status.value=='SCH'||profile.status.value=='SIG')"
                  class="btn-partner"
                  style="height: 40px;"
                  small
                  dark
                  block
                  color="grey darken-3"
                  :loading="partnerBtn.loading"
                  :disabled="partnerBtn.disabled"
                  @click.stop="toggleCodeReader()"
                >
                  {{ partnerBtn.text }}
                </v-btn>
                <v-img
                  v-else-if="profile.partner"
                  eager
                  class="white--text align-end"
                  src="@/assets/buzzer-avatar.svg"
                >
                  <v-card-title
                    class="subtitle-2 code pa-0 pb-3 ml-10 text-truncate"
                  >
                    {{ formatBuzzerCode(profile.buzzer.code) }} #{{ profile.buzzer.number }}
                  </v-card-title>
                  <icon-base
                    width="16"
                    height="16"
                    icon-name="standby-light"
                    class="standby-light secondary--text"
                    :class="{ 'error--text': statusLight }"
                  >
                    <standby-light />
                  </icon-base>
                  <v-menu
                    transition="slide-y-transition"
                    left
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        absolute
                        top
                        right
                        color="white"
                        class="mt-n2 mr-n2"
                        :loading="partnerBtn.loading"
                        v-on="on"
                      >
                        <v-icon v-text="icons.mdiDotsVertical" />
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="getBuzzer"
                      >
                        <v-list-item-title>Ver Buzzer</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="toggleCodeReader"
                      >
                        <v-list-item-title>Trocar Buzzer</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :color="unsetPartnerProgress>=1 ? 'success' : 'primary'"
                        @mousedown="unsetPartnerConfirm(true)" 
                        @mouseleave="unsetPartnerConfirm(false)" 
                        @mouseup="unsetPartnerConfirm(false)" 
                        @touchstart="unsetPartnerConfirm(true)" 
                        @touchend="unsetPartnerConfirm(false)" 
                        @touchcancel="unsetPartnerConfirm(false)"
                        @click.prevent
                        style="cursor: pointer;"
                      >
                        <v-list-item-title>Encerrar Parceria</v-list-item-title>
                        <v-progress-linear
                          v-if="unsetPartnerBtn.interval"
                          v-model="unsetPartnerProgress"
                          :indeterminate="unsetPartnerProgress>=100"
                          absolute
                          bottom
                        />
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-img>
              </v-card>
            </v-expand-transition>
          </div> 
          <v-card 
            v-if="profile.club_id!=null"
            outlined
            class="d-inline-block px-2 grey--text text-overline my-2"
            @click="selectText($refs['club_id'], true, 'ID Clube Mobees copiado!')"
          >
            <span class="text-overline">
              ID Clube Mobees: 
            </span>
            <span
              ref="club_id"
              class="font-weight-bold"
              style="text-transform: initial;"
            >
              {{ profile.club_id }}
            </span>
          </v-card>
        </div>

        <v-form
          ref="form"
          class="profile-form mb-8"
        >
          <v-list 
            flat
            two-line
            color="transparent"
          >
            <v-list-group 
              :ripple="false"
              class="form-group"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Pessoal</v-list-item-title>
                  <v-list-item-subtitle>Dados de documentação, endereço, etc</v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <div class="form-section pa-4 pb-0">
                <v-text-field
                  v-model="profile.fullname"
                  :loading="fields.fullname.loading"
                  :disabled="fields.fullname.disabled"
                  :label="fields.fullname.label"
                  :hint="fields.fullname.hint"
                  :error-messages="fields.fullname.messages"
                  @keyup="profileChanged('fullname')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.email"
                  :loading="fields.email.loading"
                  :disabled="fields.email.disabled"
                  :label="fields.email.label"
                  :hint="fields.email.hint"
                  :error-messages="fields.email.messages"
                  @keyup="profileChanged('email')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.cpf"
                  v-mask="fields.cpf.mask"
                  :loading="fields.cpf.loading"
                  :disabled="fields.cpf.disabled"
                  :label="fields.cpf.label"
                  :hint="fields.cpf.hint"
                  @keyup="profileChanged('cpf')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.cnh"
                  v-mask="fields.cnh.mask"
                  :loading="fields.cnh.loading"
                  :disabled="fields.cnh.disabled"
                  :label="fields.cnh.label"
                  :hint="fields.cnh.hint"
                  :error-messages="fields.cnh.messages"
                  @keyup="profileChanged('cnh')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.rg.id"
                  :loading="fields.rg.id.loading"
                  :disabled="fields.rg.id.disabled"
                  :label="fields.rg.id.label"
                  :error-messages="fields.rg.id.messages"
                  :rules="fields.rg.id.rules"
                  counter="15"
                  @keyup="profileChanged('rg.id')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.rg.issuer"
                  :loading="fields.rg.issuer.loading"
                  :disabled="fields.rg.issuer.disabled"
                  :label="fields.rg.issuer.label"
                  :error-messages="fields.rg.issuer.messages"
                  @keyup="profileChanged('rg.issuer')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.phone"
                  v-mask="fields.phone.mask"
                  :loading="fields.phone.loading"
                  :disabled="fields.phone.disabled"
                  :label="fields.phone.label"
                  :hint="fields.phone.hint"
                  :error-messages="fields.phone.messages"
                  @keyup="profileChanged('phone')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.birthdate"
                  v-mask="fields.birthdate.mask"
                  :loading="fields.birthdate.loading"
                  :disabled="fields.birthdate.disabled"
                  :label="fields.birthdate.label"
                  :hint="fields.birthdate.hint"
                  :error-messages="fields.birthdate.messages"
                  @keyup="profileChanged('birthdate')"
                  class="control"
                  outlined
                />

                <v-select
                  v-model="profile.gender"
                  :items="fields.gender.items"
                  :loading="fields.gender.loading"
                  :disabled="fields.gender.disabled"
                  :label="fields.gender.label"
                  :placeholder="fields.gender.placeholder"
                  :hint="fields.gender.hint"
                  @change="profileChanged('gender')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.address.street"
                  :loading="fields.address.street.loading"
                  :disabled="fields.address.street.disabled"
                  :label="fields.address.street.label"
                  :hint="fields.address.street.hint"
                  :error-messages="fields.address.street.messages"
                  @keyup="profileChanged('address.street')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.address.number"
                  :loading="fields.address.number.loading"
                  :disabled="fields.address.number.disabled"
                  :label="fields.address.number.label"
                  :error-messages="fields.address.number.messages"
                  @keyup="profileChanged('address.number')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.address.compl"
                  :loading="fields.address.compl.loading"
                  :disabled="fields.address.compl.disabled"
                  :label="fields.address.compl.label"
                  :hint="fields.address.compl.hint"
                  :error-messages="fields.address.compl.messages"
                  @keyup="profileChanged('address.compl')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.address.neighborhood"
                  :loading="fields.address.neighborhood.loading"
                  :disabled="fields.address.neighborhood.disabled"
                  :label="fields.address.neighborhood.label"
                  :hint="fields.address.neighborhood.hint"
                  :error-messages="fields.address.neighborhood.messages"
                  @keyup="profileChanged('address.neighborhood')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.address.zip"
                  v-mask="fields.address.zip.mask"
                  :loading="fields.address.zip.loading"
                  :disabled="fields.address.zip.disabled"
                  :label="fields.address.zip.label"
                  :hint="fields.address.zip.hint"
                  :error-messages="fields.address.zip.messages"
                  @keyup="profileChanged('address.zip')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.address.city"
                  :loading="fields.address.city.loading"
                  :disabled="fields.address.city.disabled"
                  :label="fields.address.city.label"
                  :hint="fields.address.city.hint"
                  :error-messages="fields.address.city.messages"
                  @keyup="profileChanged('address.city')"
                  class="control"
                  outlined
                />

                <v-select
                  v-model="profile.address.state"
                  :items="fields.address.state.items"
                  :loading="fields.address.state.loading"
                  :disabled="fields.address.state.disabled"
                  :label="fields.address.state.label"
                  :hint="fields.address.state.hint"
                  :error-messages="fields.address.state.messages"
                  @keyup="profileChanged('address.state')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.address.country"
                  :loading="fields.address.country.loading"
                  :disabled="fields.address.country.disabled"
                  :label="fields.address.country.label"
                  :hint="fields.address.country.hint"
                  :success="fields.address.country.success"
                  @keyup="profileChanged('address.country')"
                  class="control"
                  outlined
                />
              </div>
            </v-list-group>

            <v-divider />
            <v-list-group 
              :ripple="false"
              class="form-group docs"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Documentos</v-list-item-title>
                  <v-list-item-subtitle>CNH, Fotos e Doc. do Veículo, etc</v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <div class="form-section pl-8 pb-0">
                <v-alert 
                  v-if="profile.status.value=='FDD'"
                  outlined
                  color="primary"
                  transition="expand-transition"
                  class="confirm body-1 py-2 mb-0"
                >
                  <div class="d-flex align-center">
                    <span>
                      Validação:
                    </span>
                    <v-spacer />
                    <m-btn-long
                      :icon="icons.mdiShimmer"
                      text
                      label="Auto validar"
                      press-color="primary"
                      :disabled="docConfirm.validating||docConfirm.loading"
                      :loading="docConfirm.validating"
                      :duration="2000"
                      @press="autoValidateDocs"
                      @cancel="toggleToast(true, 'Aperte o botão por 2s')"
                    />
                    <v-spacer />
                    <v-btn 
                      v-if="docConfirm.ok"
                      color="primary"
                      depressed
                      :loading="docConfirm.loading"
                      @click="validateDocs(docConfirm.ok)"
                    >
                      Promover
                    </v-btn>
                    <v-btn 
                      v-else-if="docConfirm.ok==null"
                      color="primary"
                      depressed
                      :disabled="docConfirm.loading"
                      @click="batchDocValidation(docConfirm.ok)"
                    >
                      Aprovar todos
                      <v-progress-linear 
                        :active="docConfirm.loading"
                        :value="docConfirm.progress"
                        absolute
                        bottom
                        stream
                      />
                    </v-btn>
                    <v-btn 
                      v-else
                      color="error"
                      depressed
                      :loading="docConfirm.loading"
                      @click="validateDocs(docConfirm.ok)"
                    >
                      Enviar Feedback
                    </v-btn>
                  </div>
                </v-alert>
                <v-list class="list">
                  <v-list-item
                    v-for="(doc, d) in docs"
                    :key="d"
                    class="item"
                    @click="toggleDoc(true, d)"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="item-title">
                        {{ doc.title | decodeWeek }} 
                        <v-icon 
                          v-if="doc.auto_validation"
                          small
                        >
                          {{ icons.mdiShimmer }}
                        </v-icon>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="doc.status==3&&doc.feedback!=''"
                        class="item-feedback mt-2"
                      >
                        💬 {{ doc.feedback }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action
                      class="item-action"
                    >
                      <v-btn 
                        icon
                        color="primary"
                        class="mr-n2"
                        @click.stop="toggleDoc(true, d)"
                      >
                        <v-icon 
                          v-if="doc.required&&doc.status==0"
                        >{{ icons.mdiClockOutline }}</v-icon>
                        <v-icon 
                          v-else-if="doc.status==2"
                        >{{ icons.mdiFileCheckOutline }}</v-icon>
                        <v-icon 
                          v-else-if="doc.status==3"
                        >{{ icons.mdiFileSyncOutline }}</v-icon>
                        <v-icon 
                          v-else-if="!doc.required"
                        >{{ icons.mdiFilePlusOutline }}</v-icon>
                        <v-icon 
                          v-else
                        >{{ icons.mdiEye }}</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
            </v-list-group>

            <v-divider />
            <v-list-group 
              :ripple="false"
              class="form-group"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Profissional</v-list-item-title>
                  <v-list-item-subtitle>Dados da rotina de trabalho</v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <div class="form-section pa-4 pb-0">
                <v-select
                  v-model="profile.job.products"
                  :items="fields.job.products.items"
                  :loading="fields.job.products.loading"
                  :disabled="fields.job.products.disabled"
                  :label="fields.job.products.label"
                  :placeholder="fields.job.products.placeholder"
                  :hint="fields.job.products.hint"
                  :rules="fields.job.products.rules"
                  :success="fields.job.products.success"
                  :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
                  multiple
                  outlined
                  class="control"
                  @change="profileChanged('job.products')"
                />
                <v-select
                  v-model="profile.job.special_projects"
                  :items="fields.job.special_projects.items"
                  :loading="fields.job.special_projects.loading"
                  :disabled="fields.job.special_projects.disabled"
                  :label="fields.job.special_projects.label"
                  :placeholder="fields.job.special_projects.placeholder"
                  :hint="fields.job.special_projects.hint"
                  :rules="fields.job.special_projects.rules"
                  :success="fields.job.special_projects.success"
                  :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
                  outlined
                  class="control"
                  @change="profileChanged('job.special_projects')"
                />
                <v-select
                  v-model="profile.job.apps"
                  :items="fields.job.apps.items"
                  :loading="fields.job.apps.loading"
                  :disabled="fields.job.apps.disabled"
                  :label="fields.job.apps.label"
                  :hint="fields.job.apps.hint"
                  :rules="fields.job.apps.rules"
                  :success="fields.job.apps.success"
                  light
                  multiple
                  outlined
                  vertical
                  color="primary"
                  class="control"
                  @change="profileChanged('job.apps')"
                />
                <v-expand-transition>
                  <m-field
                    v-if="profile.job.apps!=null&&profile.job.apps.length>1"
                    :value="profile.job.apps_percent"
                    :loading="fields.job.apps_percent.loading"
                    :disabled="fields.job.apps_percent.disabled"
                    :label="fields.job.apps_percent.label"
                    :hint="fields.job.apps_percent.hint"
                    :rules="fields.job.apps_percent.rules"
                    :success="fields.job.apps_percent.success"
                    light
                    color="primary"
                    class="apps-percent control mt-0 mb-8"
                  >
                    <div
                      v-for="selected in profile.job.apps_percent"
                      :key="'app-percent-'+selected.app"
                      class="app-percent d-flex align-center pl-4"
                    >
                      <span class="black--text text-body-1 mr-2">
                        {{ selected.text }}:
                      </span>
                      <!-- <v-spacer /> -->
                      <v-select
                        v-model="selected.value"
                        :items="fields.job.apps_percent.items"
                        :placeholder="fields.job.apps_percent.placeholder"
                        :success="fields.job.apps_percent.success"
                        :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu primary' }"
                        solo
                        flat
                        hide-details
                        color="primary"
                        class="transparent"
                        @change="updateAppsPercent(true, selected)"
                      />
                    </div>
                  </m-field>
                </v-expand-transition>

                <v-select
                  v-model="profile.job.service_model"
                  :items="fields.job.service_model.items"
                  :loading="fields.job.service_model.loading"
                  :disabled="fields.job.service_model.disabled"
                  :label="fields.job.service_model.label"
                  :placeholder="fields.job.service_model.placeholder"
                  :hint="fields.job.service_model.hint"
                  @change="profileChanged('job.service_model')"
                  class="control"
                  outlined
                />

                <v-select
                  v-model="profile.job.fulltime"
                  :items="fields.job.fulltime.items"
                  :loading="fields.job.fulltime.loading"
                  :disabled="fields.job.fulltime.disabled"
                  :label="fields.job.fulltime.label"
                  @change="profileChanged('job.fulltime')"
                  class="control"
                  outlined
                />

                <v-autocomplete
                  v-if="fields.job.region.toggle"
                  v-model="profile.job.region"
                  :items="fields.job.region.items"
                  :filter="regionFilter"
                  :loading="fields.job.region.loading"
                  :disabled="fields.job.region.disabled"
                  :label="fields.job.region.label"
                  :hint="fields.job.region.hint"
                  :rules="fields.job.region.rules"
                  :success="fields.job.region.success"
                  item-text="title"
                  item-value="title"
                  @change="profileChanged('job.region')"
                  outlined
                  color="secondary"
                  class="control"
                >
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item" />
                    </template>
                    <template v-else>
                      <v-list-item-content v-text="data.item.title" />
                    </template>
                  </template>
                </v-autocomplete>

                <v-text-field
                  v-model="profile.job.rating"
                  v-mask="fields.job.rating.mask"
                  :loading="fields.job.rating.loading"
                  :disabled="fields.job.rating.disabled"
                  :label="fields.job.rating.label"
                  :hint="fields.job.rating.hint"
                  :error-messages="fields.job.rating.messages"
                  @input="profileChanged('job.rating')"
                  class="control"
                  outlined
                />

                <v-select
                  v-model="profile.job.journey"
                  :items="fields.job.journey.items"
                  :loading="fields.job.journey.loading"
                  :disabled="fields.job.journey.disabled"
                  :label="fields.job.journey.label"
                  @change="profileChanged('job.journey')"
                  class="control"
                  outlined
                />

                <v-subheader class="px-0 mt-n4 caption">{{ fields.job.experience.label }} </v-subheader>
                <v-slider
                  v-if="fields.job.experience.toggle"
                  v-model="profile.job.experience"
                  :disabled="fields.job.experience.disabled"
                  :hint="fields.job.experience.hint"
                  :success="fields.job.experience.success"
                  thumb-label="always"
                  :max="fields.job.experience.max"
                  min="0"
                  @input="profileChanged('job.experience'); roundExperience();"
                  outlined
                  class="control mb-4"
                >
                  <template v-slot:thumb-label="{ value }">
                    <v-progress-circular 
                      v-if="fields.job.experience.loading"
                      size="16"
                      width="2"
                      indeterminate
                      color="white"
                    />
                    <v-icon 
                      v-else
                      small
                    >{{ icons.mdiArrowLeftRight }}</v-icon>
                  </template>
                  <template v-slot:prepend>
                    <span class="body-1" style="min-width: 80px; white-space: nowrap;">
                      {{ profile.job.experience | humanizeExperience }}
                    </span>
                  </template>
                </v-slider>
              </div>
            </v-list-group>

            <v-divider />
            <v-list-group 
              :ripple="false"
              class="form-group"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Veículo</v-list-item-title>
                  <v-list-item-subtitle>Dados do veículo</v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <div class="form-section pa-4 pb-0">
                <v-select
                  v-model="profile.vehicle.parking"
                  :items="fields.vehicle.parking.items"
                  :loading="fields.vehicle.parking.loading"
                  :disabled="fields.vehicle.parking.disabled"
                  :label="fields.vehicle.parking.label"
                  @change="profileChanged('vehicle.parking')"
                  class="control"
                  outlined
                />
                
                <v-text-field
                  v-model="profile.vehicle.brand"
                  :loading="fields.vehicle.brand.loading"
                  :disabled="fields.vehicle.brand.disabled"
                  :label="fields.vehicle.brand.label"
                  :hint="fields.vehicle.brand.hint"
                  :error-messages="fields.vehicle.brand.messages"
                  @keyup="profileChanged('vehicle.brand')"
                  class="control"
                  outlined
                />

                <v-autocomplete
                  v-model="profile.vehicle.model"
                  :items="fields.vehicle.model.items"
                  :loading="fields.vehicle.model.loading"
                  :disabled="fields.vehicle.model.disabled"
                  :label="fields.vehicle.model.label"
                  :hint="fields.vehicle.model.hint"
                  :error-messages="fields.vehicle.model.messages"
                  @input="profileChanged('vehicle.model')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.vehicle.color"
                  :loading="fields.vehicle.color.loading"
                  :disabled="fields.vehicle.color.disabled"
                  :label="fields.vehicle.color.label"
                  :hint="fields.vehicle.color.hint"
                  :error-messages="fields.vehicle.color.messages"
                  @keyup="profileChanged('vehicle.color')"
                  class="control"
                  outlined
                />

                <v-select
                  v-model="profile.vehicle.fuel"
                  :items="fields.vehicle.fuel.items"
                  :loading="fields.vehicle.fuel.loading"
                  :disabled="fields.vehicle.fuel.disabled"
                  :label="fields.vehicle.fuel.label"
                  :placeholder="fields.vehicle.fuel.placeholder"
                  :hint="fields.vehicle.fuel.hint"
                  :rules="fields.vehicle.fuel.rules"
                  :success="fields.vehicle.fuel.success"
                  :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
                  multiple
                  outlined
                  class="control"
                  @change="profileChanged('vehicle.fuel')"
                />

                <v-text-field
                  v-model="profile.vehicle.plate"
                  :loading="fields.vehicle.plate.loading"
                  :disabled="fields.vehicle.plate.disabled"
                  :label="fields.vehicle.plate.label"
                  :hint="fields.vehicle.plate.hint"
                  :error-messages="fields.vehicle.plate.messages"
                  @keyup="profileChanged('vehicle.plate')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.vehicle.chassis"
                  :loading="fields.vehicle.chassis.loading"
                  :disabled="fields.vehicle.chassis.disabled"
                  :label="fields.vehicle.chassis.label"
                  :hint="fields.vehicle.chassis.hint"
                  :error-messages="fields.vehicle.chassis.messages"
                  @keyup="profileChanged('vehicle.chassis')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.vehicle.year"
                  :loading="fields.vehicle.year.loading"
                  :disabled="fields.vehicle.year.disabled"
                  :label="fields.vehicle.year.label"
                  :hint="fields.vehicle.year.hint"
                  :error-messages="fields.vehicle.year.messages"
                  @keyup="profileChanged('vehicle.year')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.vehicle.renavam"
                  :loading="fields.vehicle.renavam.loading"
                  :disabled="fields.vehicle.renavam.disabled"
                  :label="fields.vehicle.renavam.label"
                  :error-messages="fields.vehicle.renavam.messages"
                  @keyup="profileChanged('vehicle.renavam')"
                  class="control"
                  outlined
                />

                <v-select
                  v-model="profile.vehicle.isOwner"
                  :items="fields.vehicle.isOwner.items"
                  :loading="fields.vehicle.isOwner.loading"
                  :disabled="fields.vehicle.isOwner.disabled"
                  :label="fields.vehicle.isOwner.label"
                  :hint="fields.vehicle.isOwner.hint"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="fields.vehicle.isOwner.rules"
                  :success="fields.vehicle.isOwner.success"
                  @change="profileChanged('vehicle.isOwner');"
                  outlined
                  color="secondary"
                  class="control"
                />

                <v-expand-transition>
                  <div
                    v-if="profile.vehicle.isOwner==2"
                    class="other-owner"
                  >
                    <v-text-field
                      v-model="profile.vehicle.rental"
                      :loading="fields.vehicle.rental.loading"
                      :disabled="fields.vehicle.rental.disabled"
                      :label="fields.vehicle.rental.label"
                      :rules="fields.vehicle.rental.rules"
                      :success="fields.vehicle.rental.success"
                      @input="profileChanged('vehicle.rental')"
                      outlined
                      color="secondary"
                      class="control"
                    />
                  </div>
                  <div
                    v-else-if="profile.vehicle.isOwner==0"
                    class="other-owner"
                  >
                    <v-text-field
                      v-model="profile.vehicle.ownerRelative"
                      :loading="fields.vehicle.ownerRelative.loading"
                      :disabled="fields.vehicle.ownerRelative.disabled"
                      :label="fields.vehicle.ownerRelative.label"
                      :rules="fields.vehicle.ownerRelative.rules"
                      :success="fields.vehicle.ownerRelative.success"
                      @input="profileChanged('vehicle.ownerRelative')"
                      outlined
                      color="secondary"
                      class="control"
                    />
                    <v-text-field
                      v-model="profile.vehicle.ownerName"
                      :loading="fields.vehicle.ownerName.loading"
                      :disabled="fields.vehicle.ownerName.disabled"
                      :label="fields.vehicle.ownerName.label"
                      :rules="fields.vehicle.ownerName.rules"
                      :success="fields.vehicle.ownerName.success"
                      @input="profileChanged('vehicle.ownerName')"
                      outlined
                      color="secondary"
                      class="control"
                    />
                    <v-text-field
                      v-model="profile.vehicle.ownerEmail"
                      :loading="fields.vehicle.ownerEmail.loading"
                      :disabled="fields.vehicle.ownerEmail.disabled"
                      :label="fields.vehicle.ownerEmail.label"
                      :hint="fields.vehicle.ownerEmail.hint"
                      :error-messages="fields.vehicle.ownerEmail.messages"
                      @keyup="profileChanged('vehicle.ownerEmail')"
                      class="control"
                      outlined
                    />
                    <v-text-field
                      v-model="profile.vehicle.ownerRg"
                      :loading="fields.vehicle.ownerRg.loading"
                      :disabled="fields.vehicle.ownerRg.disabled"
                      :label="fields.vehicle.ownerRg.label"
                      :rules="fields.vehicle.ownerRg.rules"
                      :success="fields.vehicle.ownerRg.success"
                      @input="profileChanged('vehicle.ownerRg')"
                      outlined
                      color="secondary"
                      class="control"
                    />
                    <v-text-field
                      v-model="profile.vehicle.ownerOrg_exp"
                      :loading="fields.vehicle.ownerOrg_exp.loading"
                      :disabled="fields.vehicle.ownerOrg_exp.disabled"
                      :label="fields.vehicle.ownerOrg_exp.label"
                      :rules="fields.vehicle.ownerOrg_exp.rules"
                      :success="fields.vehicle.ownerOrg_exp.success"
                      @input="profileChanged('vehicle.ownerOrg_exp')"
                      outlined
                      color="secondary"
                      class="control"
                    />
                    <v-text-field
                      v-model="profile.vehicle.ownerCpf"
                      :loading="fields.vehicle.ownerCpf.loading"
                      :disabled="fields.vehicle.ownerCpf.disabled"
                      :label="fields.vehicle.ownerCpf.label"
                      :rules="fields.vehicle.ownerCpf.rules"
                      :success="fields.vehicle.ownerCpf.success"
                      @input="profileChanged('vehicle.ownerCpf')"
                      outlined
                      color="secondary"
                      class="control"
                    />
                  </div>
                </v-expand-transition>
                <v-btn
                  outlined
                  block
                  large
                  :disabled="!(!!profile.vehicle.envelope_id&&profile.vehicle.envelope_id!='')"
                  color="error"
                  class="mb-2"
                  @click="profile.vehicle.envelope_id=''; profileChanged('vehicle.envelope_id')"
                >
                  Reset Docusign
                </v-btn>
                <p class="text-caption text--disabled text-center mb-8">
                  {{ profile.vehicle.envelope_id }}
                </p>
              </div>
            </v-list-group>

            <v-divider />
            <v-list-group 
              :ripple="false"
              class="form-group"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Pagamento</v-list-item-title>
                  <v-list-item-subtitle>Dados bancários</v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <div class="form-section pa-4 pb-0">
                <v-combobox
                  v-model="profile.payment.bank"
                  :items="bankList"
                  :search-input.sync="bankKeyword"
                  :loading="fields.payment.bank.loading"
                  :disabled="fields.payment.bank.disabled"
                  :label="fields.payment.bank.label"
                  :hint="fields.payment.bank.hint"
                  :error-messages="fields.payment.bank.messages"
                  hide-no-data
                  hide-selected
                  item-text="title"
                  item-value="id"
                  return-object
                  @change="profileChanged('payment.bank.id')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.payment.branch"
                  :loading="fields.payment.branch.loading"
                  :disabled="fields.payment.branch.disabled"
                  :label="fields.payment.branch.label"
                  :hint="fields.payment.branch.hint"
                  :error-messages="fields.payment.branch.messages"
                  @keyup="profileChanged('payment.branch')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.payment.account"
                  :loading="fields.payment.account.loading"
                  :disabled="fields.payment.account.disabled"
                  :label="fields.payment.account.label"
                  :hint="fields.payment.account.hint"
                  :error-messages="fields.payment.account.messages"
                  @keyup="profileChanged('payment.account')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.payment.digit"
                  :loading="fields.payment.digit.loading"
                  :disabled="fields.payment.digit.disabled"
                  :label="fields.payment.digit.label"
                  :hint="fields.payment.digit.hint"
                  :error-messages="fields.payment.digit.messages"
                  @keyup="profileChanged('payment.digit')"
                  class="control"
                  outlined
                />

                <v-select
                  v-model="profile.payment.type"
                  :items="fields.payment.type.items"
                  :loading="fields.payment.type.loading"
                  :disabled="fields.payment.type.disabled"
                  :label="fields.payment.type.label"
                  :hint="fields.payment.type.hint"
                  :error-messages="fields.payment.type.messages"
                  @change="profileChanged('payment.type')"
                  class="control"
                  outlined
                />

                <v-text-field
                  v-model="profile.payment.compl"
                  :loading="fields.payment.compl.loading"
                  :disabled="fields.payment.compl.disabled"
                  :label="fields.payment.compl.label"
                  :hint="fields.payment.compl.hint"
                  :error-messages="fields.payment.compl.messages"
                  @keyup="profileChanged('payment.compl')"
                  class="control"
                  outlined
                />
              </div>
            </v-list-group>

            <v-divider />
            <v-list-group 
              :ripple="false"
              class="form-group"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    Revisões
                    <v-btn 
                      icon
                      small
                      :loading="controller.reviews.loading"
                      @click.stop="getReviewHistory"
                    >
                      <v-icon small>{{ icons.mdiRefresh }}</v-icon>
                    </v-btn>
                  </v-list-item-title>
                  <v-list-item-subtitle>Histórico de visitas à Colmeia</v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <v-list class="list pl-4">
                <v-list-item>
                  <v-list-item-content>
                    <p
                      v-for="review in profile.reviews"
                      :key="'reviews-'+review.data"
                      class="sub-item text-body-2 mb-2"
                    >
                      {{ review | formatReview }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-group>

            <v-divider />
            <v-list-group 
              :ripple="false"
              class="form-group"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>App</v-list-item-title>
                  <v-list-item-subtitle>Informações do aplicativo</v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <v-list class="list pl-4">
                <v-list-item class="item">
                  <v-list-item-content>
                    <v-list-item-title>
                      Versão Instalada: <b>{{ profile.app_version }}</b>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="item">
                  <v-list-item-content>
                    <v-list-item-title>
                      Sistema Operacional: <b>{{ profile.os }}</b>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="item">
                  <v-list-item-content>
                    <v-list-item-title>
                      GPS: <b>{{ profile.gps | enabledText }}</b>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="item">
                  <v-list-item-content>
                    <v-list-item-title>
                      Push: <b>{{ profile.push | enabledText }}</b>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-group>
          </v-list>
        </v-form>
      </v-card-text>

      <v-fab-transition>
        <v-btn
          v-show="toggleSaveBtn"
          color="primary"
          class="btn-save mb-12 mr-2"
          dark
          absolute
          bottom
          right
          fab
          :loading="saveBtn.loading"
          :disabled="saveBtn.disabled"
          @click="updateProfile(null)"
        >
          <v-icon>{{ icons.mdiCheck }}</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-sheet>

    <doc-view
      :toggle="docView.toggle"
      :loading="docView.loading"
      :docs="docs"
      :selected="docView.selected"
      @feedback="setDocFeedback"
      @validate="validateDocs"
      @close="toggleDoc(false)"
    />
  </v-dialog>
</template>

<style>

  .v-dialog--fullscreen > .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 0;
  }

  .control {
    margin: 16px 0;
  }
  .tags.control {
    max-width: 288px;
  }

  .driver .close-btn {
    top: 1rem;
    right: 1rem;
  }

  .driver {
    position: relative;
  }

  .driver .status {
    width: 288px;
  }

  .driver .fleet {
    width: 288px;
  }

  .profile-header .title {
    line-height: 1.25;
  }

  .profile-form .overline {
    margin-top: 16px;
  }

  .profile-img { 
    position: relative;
  }
  .profile-img .v-avatar {
    overflow: initial;
  } 

  .btn-upload {
    right: -12px !important;
    bottom: -12px !important;
  }

  .profile-img .btn-ok, .profile-img .btn-cancel, .profile-img .btn-rotate {
    left: auto;
    right: 3.25rem;
  }
  .profile-img .btn-cancel {
    top: 2rem !important;
  }
  .profile-img .btn-rotate {
    top: calc(50% - 1.25rem) !important;
    right: 2rem;
  }
  .profile-img .btn-ok {
    bottom: 2rem !important;
  }

  .croppa-container {
    width: 240px;
    height: 240px;
    background: var(--mobees-black);
    border-radius: 50%;
    overflow: hidden;
  }

  .reader {
    position: relative;
  }

  .qr-code {
    width: 100%;
    min-height: 240px;
  }

  .driver .buzzer .v-image {
    max-height: 32vw;
    cursor: pointer;
  }
  .buzzer .buzzer-header {
    position: relative;
  }

  .buzzer .buzzer-avatar {
    border-radius: .25rem;
  }

  .buzzer .title.code {
    font-size: .875rem !important;
    opacity: .5;
  }

  .buzzer .standby-light {
    position: absolute;
    bottom: 12px;
    left: 12px;
    width: 24px;
  }

  .buzzer .close-btn {
    top: .5rem;
    right: .5rem;
  }

  .driver .btn-new, .driver .btn-save {
  }

  .driver .list .item-feedback {
    line-height: 1.4;
    text-overflow: initial;
    white-space: initial;
  }

  .driver .list .item:not(:last-child) {
    border-bottom: 1px solid var(--light-border) !important;
  }


</style>

<script>
  // Icons
  import {
    mdiHelpCircleOutline,
    mdiEye,
    mdiEyeOff,
    mdiClose,
    mdiCheck,
    mdiPencil,
    mdiDotsVertical,
    mdiRotateLeft,
    mdiArrowLeftRight,
    mdiClockOutline,
    mdiFileCheckOutline,
    mdiFileSyncOutline,
    mdiFilePlusOutline,
    mdiStarOutline,
    mdiStar,
    mdiRefresh,
    mdiPlus,
    mdiTransitConnectionHorizontal,
    mdiShimmer
  } from '@mdi/js'

  // Utilities
  import services from '@/services'
  import { driver as dictionary } from '@/dictionary'
  import { fields, status, docs } from '@/services/driver'
  import { sync, get } from 'vuex-pathify'
  import { mask } from 'vue-the-mask'
  import _ from 'lodash';
  const moment = require('moment');
  import cities from '@/assets/data/cidades.json'


  export default {
    name: 'Driver',

    components: {
      DocView: () => import('@/components/DocView'),
      QrCodeReader: () => import('@/components/QrCodeReader'),
      IconBase: () => import('@/components/IconBase'),
      AvatarDefault: () => import('@/components/icons/AvatarDefault'),
      IconBase: () => import('@/components/IconBase'),
      StandbyLight: () => import('@/components/icons/StandbyLight'),
      MField: () => import('@/components/mField'),
      MBtnLong: () => import('@/components/mBtnLong'),
    },

    props: {
      toggle: {
        type: Boolean,
        default: false,
      },
      data: {
        type: Object,
        default: () => null
      },
      loadingView: {
        type: Boolean,
        default: false,
      }
    },

    data: () => ({
      icons: {
        mdiHelpCircleOutline,
        mdiEye,
        mdiEyeOff,
        mdiClose,
        mdiCheck,
        mdiPencil,
        mdiDotsVertical,
        mdiRotateLeft,
        mdiArrowLeftRight,
        mdiClockOutline,
        mdiFileCheckOutline,
        mdiFileSyncOutline,
        mdiFilePlusOutline,
        mdiStarOutline,
        mdiStar,
        mdiRefresh,
        mdiPlus,
        mdiTransitConnectionHorizontal,
        mdiShimmer
      },
      toggleUpload: false,
      cropper: {},
      status,
      photoToggle: false,
      saveBtn: {
        disabled: false,
        loading: false
      },
      codeReader: {
        toggle: false,
      },
      partnerBtn: {
        text: 'Associar Buzzer',
        disabled: false,
        loading: false
      },
      buzzerBtn: {
        disabled: false,
        loading: false
      },
      unsetPartnerBtn: {
        loading: false,
        interval: false,
        count: 0,
        threshold: 3000,
      },
      profile: {},
      docs,
      show: false,
      deadline: null,
      docView: {
        toggle: false,
        loading: false,
        selected: null,
      },
      docConfirm: {
        toggle: false,
        loading: false,
        validating: false,
        ok: null,
      },
      changed: [],
      fields,
      controller: {
        reviews: {
          loading: false
        },
        status: {
          history: [],
          loading: false,
          updated: false,
        },
      },
      bankKeyword: '',
      dictionary
    }),

    filters: {
      brDate (v) {
        return moment(v).format('DD/MM/YY hh:mm');
      },
      humanizeExperience (v) {
        const m = moment.duration(v, 'months');
        return v==0 ? 'Selecione:' : m.humanize();
      },
      enabledText (v) {
        return !!v ? 'Habilitado' : 'Desabilitado';
      },
      decodeWeek (text) {
        if (!_.isNil(text)&&text.indexOf('*')>=0) {
          const breakdown = text.split('*');
          const n = parseInt(breakdown[1].substr(-1, 1));
          const week = 'Semana anterior '+n;
          return text.replace('*week-'+n+'*', week);
        }else{
          return text;
        }
      },
      formatReview (review) {
        const labels = review.labels.length ? _.join(review.labels, '|') : 'Revisão';
        return moment.utc(review.data).local().format('DD/MM/YY HH:mm') + ' – ' + labels + (review.buzzerTrocado=='true' ? '(Buzzer trocado)' : '');
      }
    },

    computed: {
      views: sync('app/views'),
      loading: sync('app/views@loading'),
      view: sync('app/views@drivers'),
      items: sync('drivers/data@items'),
      selected: sync('drivers/data@selected'),
      tags: sync('drivers/data@tags'),
      fleets: sync('drivers/data@fleets'),
      prototype: sync('drivers/prototype'),
      bankList: get('drivers/bankList'),
      buzzers: sync('buzzers/data@items'),
      user: sync('user/data'),
      toast: sync('app/toast'),

      ready () {
        return !_.isEmpty(this.profile);
      },

      size () {
        return this.breakpoint(null, 'xs') ? '100vw' : this.breakpoint(null, 'sm') ? '60vw' : '32rem'
      },

      toggleSaveBtn () {
        let b = false;
        if(this.changed.length>0){
          b = true;
        }
        return b;
      },

      buzzer () {
        return this.buzzers[this.profile.buzzer.code];
      },

      statusLight () {
        return this.isValid(this.buzzer) ? this.buzzer.status==='ON' ? false : true : false;
      },
      
      allowedStatus () {
        const profile = this.profile;
        const status = _.has(profile, 'status') ? profile.status.value : null;
        return !!status ? _.map(this.status, (item) => {
          if (item.mustbe==null||_.indexOf(item.mustbe, status)>=0||(status==item.value)) {
            item.disabled = false;
          }else{
            item.disabled = true;
          }
          return item;
        }) : null;
      },

      showDocs () {
        const s = this.profile.status.value;
        return s=='ENR'||s=='FDD'||s=='DOC'||s=='SIG'||s=='SCH'||s=='REA'||s=='ACT'||s=='DEN';
      },

      verifiedDocs () {
        return _.every(this.docs, doc => {
          return doc.required==1 ? (doc.status==2 || doc.status==3) : true;
        });
      },

      unsetPartnerProgress () {
        const p = this.unsetPartnerBtn.count / this.unsetPartnerBtn.threshold;
        console.log(p);
        return p*100;
      },

      fleet () {
        const id = this.profile.fleet;
        const fleet = id in this.fleets ? this.fleets[id] : null;
        return fleet;
      }
    },

    watch: {
      unsetPartnerProgress: function (v) {
        if(v>=100) {
          console.log('unsetPartner', 'confirmed');
          this.unsetPartnerConfirm(false);
          this.unsetPartner();
        }
      },
      toggle: {
        immediate: true,
        handler (b) {
          console.log(b);
          if (b) {
            this.updateView();
          }
        }
      },
    },

    methods: {
      ...services,

      toggleDoc (b, d) {
        this.docView.toggle = b;
        this.docView.selected = _.isNil(d) ? null : d;
      },

      closeView () {
        console.log('closeView');
        this.selected = null;
        this.profile = {};
        this.controller.status.history = [];
        this.controller.status.updated = false;
        setTimeout(() => {
          this.$router.push({ path: `/drivers` });
        }, 250);
      },

      docPending (status) {
        return status==0 || status==3;
      },

      updateView () {
        console.log('update')
        if (!!this.selected) {
          const data = _.clone(this.data);
          this.$set(this, 'profile', data);
          this.photoToggle = false;
          this.changed = [];
          this.docConfirm.ok = null;
          this.controller.status.history = [];
          this.controller.status.updated = false;
          this.$nextTick(() => {
            this.updateApps();
            this.updateRequiredDocs();
          })
        }
      },

      updateRequiredDocs () {
        if (!!this.profile.docs) {
          let docs = _.merge({}, _.clone(this.docs), this.profile.docs);

          const owner = this.profile.vehicle.isOwner==1;
          docs = _.mapValues(docs, (doc, d) => {
            if (doc.status==3) this.docConfirm.ok = false;
            if (d=='doc_rg_prop') {
              doc.required = owner ? 0 : 1;
            }
            return doc;
          });
          const doc_status = _.reduce(docs, (status, d) => {
            status[(d.required==1 && d.status==2)||d.required==0 ? 'ok' : d.required==1 && _.indexOf([0,3], d.status)>=0 ? 'feedback' : 'pending'] += 1;
            return status;
          }, { ok: 0, feedback: 0, pending: 0 });
          if (this.profile.status.value=='FDD') this.docConfirm.ok = doc_status.feedback>0 ? false : doc_status.pending==0 || null;
          //this.docs = owner ? _.pickBy(docs, ['required', 1]) : docs;
          this.docs = _.assignIn({}, docs);
          console.log(docs);
        }
      },

      setField (field, value) {
        _.set(this.profile, field, value);
        this.profileChanged(field);
      },

      toggleFullscreen () {
        const viewport = { x: window.innerWidth, y: window.innerHeight };
        if (viewport.x<600) {
          this.view.fullscreen = true;
        }else{
          this.view.fullscreen = false;
        }
      },

      toggleCodeReader (b) {
        this.codeReader.toggle = !this.codeReader.toggle;
        if(this.codeReader.toggle) {
          this.partnerBtn.text = 'Cancelar';
        }else{
          this.partnerBtn.text = 'Associar Buzzer';
          this.fields.buzzer.value = null;
        }
      },

      codeResponse (response) {
        if(response.type==='success'){
          this.toggleToast(
            true,
            response.value,
            5000,
            false,
          );
          this.toggleCodeReader(false);
          this.fields.buzzer.value = response.value;
          this.setPartner(response.value);
        }else{
          this.toggleToast(
            true,
            response.value,
            5000,
            false,
            'error'
          );
        }
      },

      handleCropperInit () {
        this.toggleUpload = false;
      },

      toggleCropper (b) {
        // this.toggleUpload = b;
        if(b) {
          this.$refs.cropper.remove();
          this.$refs.cropper.chooseFile();
        }else{
          this.$refs.cropper.remove();
          this.toggleUpload = false;
        }
      },

      handleNewImage (){
        this.toggleUpload = true;

        // Fix photo orientation
        const meta = this.cropper.getMetadata();
        meta.orientation = 1;
        this.cropper.applyMetadata(meta);
        console.log(meta);
      },

      prepareImgUpload () {
        this.toggleUpload = false;
        this.profile.photo = this.$refs.cropper.generateDataUrl('image/jpeg', 0.6);
        this.profileChanged('photo');
      },

      onFileTypeMismatch () {
        this.toggleToast(
          true,
          'Ops! Formato de imagem inválido',
          5000,
          false,
        );
      },

      onFileSizeExceed () {
        this.toggleToast(
          true,
          'O arquivo excede o limite de 1MB.',
          5000,
          false,
        );
      },

      profileChanged(field){
        // log change
        if(this.changed.indexOf(field)===-1){
          this.changed.push(field);
        }
        if (field=='job.apps') {
          this.updateApps();
        }
        console.log(this.changed);
      },

      translateNotation(field) {
        let f;
        if (_.has(this.dictionary, field)) {
          f = this.dictionary[field];
        }else{
          f = _.invert(this.dictionary)[field];
        }
        return f;
      },

      regionFilter (item, query, text) {
        if (_.has(item, 'title')) {
          const normalized = item.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
          return new RegExp(query.toLowerCase()).test(normalized);
        }else{
          return false;
        }
      },

      roundExperience () {
        const xp = this.profile.job.experience;
        this.profile.job.experience = xp > 10 ? _.round(xp / 12) * 12 : xp;
      },

      fieldAlerts (fields) {
        _.map(this.fields, (field) => {
          if (_.has(field, 'messages')) {
            field.messages = '';
          }else{
            _.map(field, (f) => {
              if (_.has(f, 'messages')) {
                f.messages = '';
              }
            });
          }
        });
        _.map(fields, (f, k) => {
          _.set(this.fields, this.translateNotation(k) + '.messages', f);
        });
      },

      getBuzzer () {
        const buzzer = this.profile.buzzer.code;
        setTimeout(() => {
          this.$router.push({ path: `/buzzers/${this.rawData(buzzer)}` });
          this.profile = {};
          this.selected = null;
        }, 250);
      },

      humanizeBool (data) {
        return data ? 'Sim' : 'Não';
      },

      batchDocValidation (ok) {
        if (ok==null) {
          const doc = _.find(this.docs, { 'required': 1, 'status': 1 });
          if (!_.isNil(doc)) {
            this.setDocFeedback(_.assign({}, doc, { status: 2 }), true);
          }
        }
      },

      setDocFeedback (doc, batch) {
        const username = this.user.username;
        const token = this.user.auth.token;
        const cpf = this.rawData(this.profile.cpf);
        this.docConfirm.loading = true;
        this.docs[doc.criteria].loading = true;
        console.log(doc);

        this.$api.COM({
          url: '/setcriteriadocdriveradmin',
            method: 'POST',
            data: {
              useradmin: username,
              authTk: token,
              cpf: cpf,
              criteria: doc.criteria,
              status: doc.status,
              feedback: doc.feedback,
              required: doc.required,
            }
          })
          .then(response => {
            console.log('setDocFeedback => ',response);
            if(response.data.retorno==200){
              this.profile.docs[doc.criteria] = doc;
              this.docs[doc.criteria].success = true;

              this.updateRequiredDocs();

              if (!_.isNil(batch)&&batch) {
                this.docConfirm.progress = (_.filter(this.docs, { 'required': 1, 'status': 1 }).length / _.keys(this.docs).length) * 100;
                this.batchDocValidation(this.docConfirm.ok);
              }

              this.toggleToast(
                true,
                'Feedback salvo 👍',
                3000,
                false,
              );
            }else{
              this.toggleToast(
                true,
                'Não foi possível salvar feedback. Tente novamente, por favor.',
                5000,
                false,
              );
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.docConfirm.loading = false;
            this.docs[doc.criteria].loading = false; 
          });
      },

      validateDocs (ok) {
        const username = this.user.username;
        const token = this.user.auth.token;
        const cpf = this.rawData(this.profile.cpf);
        this.docConfirm.loading = true;

        this.$api.COM({
          url: '/validatedocdriveradmin',
            method: 'POST',
            data: {
              useradmin: username,
              authTk: token,
              cpf: cpf,
              validated: ok ? 1 : 0,
            }
          })
          .then(response => {
            console.log('validateDocs => ',response);
            if(response.data.retorno==200){
              const status = this.profile.status;
              status.super = status.value = response.data.status_motorista;
              status.changedAt = moment().toISOString();
              this.$emit('updated', this.profile);

              let text = 'Feedback enviado!';
              if (ok) {
                text = 'Motorista promovido!';
              }
              this.toggleToast(
                true,
                text + ' 👍',
                5000,
                false,
              );
            }else{
              this.toggleToast(
                true,
                'Não foi realizar a operação. Tente novamente, por favor.',
                5000,
                false,
              );
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.docConfirm.loading = false;
          });
      },

      autoValidateDocs () {
        const username = this.user.username;
        const token = this.user.auth.token;
        const cpf = this.rawData(this.profile.cpf);
        this.docConfirm.validating = true;

        this.$api.COM({
          url: '/driver/processdocs/'+username+'/'+token+'/'+cpf,
            method: 'GET',
          })
          .then(response => {
            console.log('autoValidateDocs => ',response);

            this.profile.docs = _.has(response.data, 'documentos') ? _.keyBy(JSON.parse(response.data.documentos), 'criteria') : null;
            this.$emit('updated', this.profile);
            this.updateRequiredDocs();

            this.toggleToast(
              true,
              'Documentos verificados. Confira a avaliação',
              5000,
              false,
            );
          })
          .catch(error => {
            this.toggleToast(
              true,
              'Não foi realizar a operação. Tente novamente, por favor.',
              5000,
              false,
            );
            this.handleError(error);
          })
          .finally(() => {
            this.docConfirm.validating = false;
          });
      },

      setRating () {
        let data = {}
        data[this.translateNotation('rating')] = this.profile.rating - 3;
        this.updateProfile(data);
      },

      toggleTags (b) {
        if (b) setTimeout(($) => {
          const field = $.$refs.tags;
          field.focus()
        }, 250, this);
        this.fields.tags.toggle = b;
      },

      updateApps () {
        // update apps_percent
        const apps = this.profile.job.apps;
        const options = _.keyBy(this.fields.job.apps.items, 'value');
        const nilled = apps==null||_.isEmpty(apps) ? null : _.some(options, option => {
          const selected = _.indexOf(apps, option.value)>=0;
          return selected&&_.has(option, 'nil')&&option.nil;
        });
        if (nilled) {
          this.$set(this.profile.job, 'apps', [_.find(options, ['nil', true]).value]);
        }
        this.fields.job.apps.items = _.map(options, option => {
          const nil = _.has(option, 'nil')&&option.nil;
          return { ...option, disabled: nilled!=null&&(!nil&&nilled||nil&&!nilled) }
        });
        let percent = this.profile.job.apps_percent;
        let balance = false;
        const changed = !!apps&&!!percent&&percent.length!=apps.length;
        if (!!apps) {
          if (percent==null||changed) {
            balance = apps.length>1;
            percent = _.map(_.filter(_.clone(this.profile.job.apps), (app) => !(_.has(options[app], 'nil')&&options[app].nil)), (app) => {
              return {
                text: options[app].text,
                app,
                value: apps.length==1 ? 100 : null
              }
            });
          }
        }else{
          percent = null;
        }
        this.$set(this.profile.job, 'apps_percent', percent);
        this.fields.job.apps_percent.toggle = _.size(percent)>1;
        this.updateAppsPercent(balance, changed&&apps.length==1 ? _.first(apps) : null);
      },

      updateAppsPercent (balance=true, locked=null) {
        let field = this.profile.job.apps_percent;
        const controller = this.fields.job.apps_percent;
        const min = _.first(controller.options);
        const total = () => _.sumBy(_.values(field), 'value');
        const equity = (left, ratio, last) => _[last ? 'floor' : 'ceil'](left/min / ratio) * min;
        const unset = () => _.filter(field, ['value', null]).length;
        if (balance&&unset()<=1) {
          field = _.map(field, (selected, i) => {
            const value = selected.value==null ? 
              100-total() : 
              unset()>0||locked.app!=null&&locked.app==selected.app ? 
                selected.value :
                field.length==2 ? equity(100-locked.value, field.length-1, field.length-1==i) : null;
            return { ...selected, value }
          });
          this.$set(this.profile.job, 'apps_percent', field);
        }
        this.$nextTick(() => {
          const options = _.map(_.clone(controller.options), (option) => {
            const disabled = unset()<=1 ? false : total()+option>100-(min*(field.length-2));
            return { text: option+'%', value: option, disabled };
          });
          controller.items = options;
          if (locked!=null) this.profileChanged('job.apps_percent');
        });
      },

      updateProfile (data) {
        console.log(true, 'driver.updateProfile');
        this.saveBtn.loading = true;

        // iterate through changed fields and format accordingly
        if (_.isNil(data)) {
          data = {};
          const changes = this.changed;
          for(var f in changes){
            if (typeof changes[f] == 'string') {
              if(changes[f].indexOf('.')>=0){
                var s = changes[f].split('.');
                var section = s[0];
                var field = s[1];

                if(section==='vehicle'&&this.profile.vehicle.id){
                  data[this.translateNotation('vehicle.id')] = this.profile.vehicle.id;
                }

                switch (changes[f]) {
                  case 'rg.id':
                  case 'vehicle.ownerRg':
                  case 'vehicle.ownerCpf':
                  case 'address.zip':
                    data[this.translateNotation(changes[f])] = this.rawData(this.profile[section][field]);
                    break;
                  case 'job.rating':
                    data[this.translateNotation(changes[f])] = parseFloat(this.profile[section][field]);
                    break;
                  case 'job.apps':
                  case 'job.products':
                  case 'vehicle.fuel':
                    data[this.translateNotation(changes[f])] = this.profile[section][field].toString();
                    break;
                  case 'payment.bank.id':
                    data[this.translateNotation(changes[f])] = this.profile[section][field]['id'];
                    break;
                  default:
                    data[this.translateNotation(changes[f])] = this.profile[section][field];
                }
              }else{
                switch (changes[f]) {
                  case 'tags':
                    data[this.translateNotation(changes[f])] = this.profile[changes[f]].join(',');
                    break;
                  case 'phone':
                  case 'cpf':
                    data[this.translateNotation(changes[f])] = this.rawData(this.profile[changes[f]]);
                    break;
                  case 'birthdate':
                    data[this.translateNotation(changes[f])] = moment(this.profile[changes[f]],'DD-MM-YYYY').toISOString();
                    break;
                  default:
                    data[this.translateNotation(changes[f])] = this.profile[changes[f]];
                }
              }
            }
          }
        }
        console.log(data);

        const username = this.user.username;
        const token = this.user.auth.token;
        const id = this.profile.id;

        this.$api.COM ({
          url: '/updateprofiledriveradmin',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              useradmin: username,
              authTk: token,
              id_motorista: id,
              data: data
            }
          })
          .then(response => {
            console.log('updateProfile => ', response);
            if(response.data==200){
              this.toggleToast(
                true,
                'Dados salvos com sucesso!',
                5000,
                false,
              );

              this.fieldAlerts(false);
              this.changed = [];
              //this.items[this.selected] = Object.assign({}, this.items[this.selected], this.profile);
              this.$emit('updated', this.profile);
            }else{
              this.fieldAlerts(response.data);
              this.toggleToast(
                true,
                'Verifique os campos em destaque.',
                5000,
                false,
              );
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.saveBtn.loading = false;
            console.log(false, 'driver.updateProfile');
          })
      },

      getReviewHistory () {
        console.log('driver.getReviewHistory');
        this.loading = true;
        this.controller.reviews.loading = true;

        const username = this.user.username;
        const token = this.user.auth.token;
        const cpf = this.rawData(this.profile.cpf);

        this.$api.COM
          .get('/updatereviewhistoryadmin/'+username+'/'+token+'/'+cpf+'/?format=json')
          .then(response => {
            console.log('getReviewHistory => ', response);
            this.profile.reviews = _.orderBy(response.data, ['data'], ['desc']);
            this.toggleToast(
              true,
              'Dados de revisão atualizados',
              5000,
              false,
            );
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.loading = false;
            this.controller.reviews.loading = false;
            console.log('driver.getReviewHistory');
          })
      },

      getStatusHistory () {
        if (this.controller.status.updated) return;
        console.log('driver.getStatusHistory');
        this.loading = true;
        this.controller.status.loading = true;

        const options = _.keyBy(_.cloneDeep(this.status), 'value');
        
        this.controller.status.history = [{
          ...options[this.profile.status.value],
          timestamp: this.profile.status.changedAt,
          value: this.profile.status.value,
        }];

        const username = this.user.username;
        const token = this.user.auth.token;
        const cpf = this.rawData(this.profile.cpf);

        this.$api.COM
          .get('/driver/status/'+username+'/'+token+'/'+cpf+'/?format=json')
          .then(response => {
            console.log('getStatusHistory => ', response);
            this.controller.status.history = _.orderBy(_.map(response.data, (s) => {
              const { status_motorista: value, dt_inicio: timestamp } = s;
              return { ..._.clone(options[value]), value, timestamp };
            }), ['timestamp'], ['desc']);
            this.controller.status.updated = true;
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.loading = false;
            this.controller.status.loading = false;
            console.log('driver.getStatusHistory');
          })
      },

      setStatus () {
        console.log(true, 'driver.setStatus');
        this.fields.status.loading = this.fields.status.disabled = true;

        const username = this.user.username;
        const token = this.user.auth.token;
        const cpf = this.rawData(this.profile.cpf);
        const status = this.profile.status;

        this.$api.COM
          .get('/setstatusdriveradmin/'+username+'/'+token+'/'+cpf+'/'+status.value+'/?format=json')
          .then(response => {
            console.log('setStatus => ', response);
            status.value = response.data.dados[0].status_motorista;
            status.super = response.data.dados[0].super_status_motorista;
            status.changedAt = moment().toISOString();
            this.$emit('updated', this.profile);
            this.toggleToast(
              true,
              'Status alterado!',
              5000,
              false,
            );
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.fields.status.loading = this.fields.status.disabled = false;
            console.log(false, 'driver.setStatus');
          })
      },

      setPartner (code) {
        console.log(true, 'driver.setPartner');
        this.partnerBtn.text = 'Associando...';
        this.partnerBtn.loading = true;
        this.fields.buzzer.loading = true;

        const cpf = this.rawData(this.profile.cpf);
        console.log(code);
        const buzzer = this.rawData(code);

        const username = this.user.username;
        const token = this.user.auth.token;

        let action = 'createpartner'
        if (_.has(this.profile.buzzer,'code')&&this.profile.buzzer.code!=''&&this.profile.buzzer.code!=null&&this.profile.buzzer!=null) {
          action = 'changepartner';
        }

        this.$api.COM
          .get('/'+action+'/'+username+'/'+token+'/'+cpf+'/'+buzzer+'/'+100+'/?format=json')
          .then(response => {
            console.log('setPartner => ',response);
            if(response.data[0].retorno==201||response.data[0].retorno==200){
              this.profile.partner = true;
              this.profile.buzzer.code = buzzer;
              if (action=='createpartner') this.profile.status.value = 'REA';
              this.toggleCodeReader(false);
              this.$emit('updated', this.profile);

              this.toggleToast(
                true,
                'Parceria estabelecida com sucesso!',
                5000,
                false,
                'success'
              );
            }else if(response.data[0].retorno==401){
              this.getout();
              this.handleError(error, 'Sua sessão expirou...');
            }else if(response.data[0].retorno>=10000){
              this.toggleToast(
                true,
                'Status motorista inválido.',
                5000,
                false,
              );
            }else if(response.data[0].retorno>=1000){
              this.toggleToast(
                true,
                'Dados incompletos.',
                5000,
                false,
              );
            }else if(response.data[0].retorno>=100){
              this.toggleToast(
                true,
                'Buzzer indisponível para parceria.',
                5000,
                false,
              );
            }else if(response.data[0].retorno>=10){
              this.toggleToast(
                true,
                'Buzzer não está ativo.',
                5000,
                false,
              );
            }else if(response.data[0].retorno>=1){
              this.toggleToast(
                true,
                'Percentual motorista é inválido.',
                5000,
                false,
              );
            }else{
              this.handleError(response.data.retorno, 'Erro desconhecido. Vamos investigar o que houve! 😊');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.partnerBtn.text = 'Associar a Buzzer';
            this.partnerBtn.loading = this.partnerBtn.disabled = false;
            this.fields.buzzer.loading = false;
            console.log(false, 'driver.setPartner');
          });
      },

      unsetPartnerConfirm (b) {
        if (b) {
          if(!this.unsetPartnerBtn.interval){
            this.unsetPartnerBtn.interval = setInterval(($) => {
              $.unsetPartnerBtn.count += 100
            }, 100, this);
          }
          console.log('unsetPartner', 'press');
        }else{
          console.log('unsetPartner', 'cancel');
          clearInterval(this.unsetPartnerBtn.interval);
          this.unsetPartnerBtn.interval = false;
          this.unsetPartnerBtn.count = 0;
        }
      },

      unsetPartner () {
        console.log(true, 'driver.unsetPartner');
        this.partnerBtn.loading = true;

        const cpf = this.rawData(this.profile.cpf);

        const username = this.user.username;
        const token = this.user.auth.token;

        this.$api.COM
          .get('/endpartner/'+username+'/'+token+'/'+cpf+'/?format=json')
          .then(response => {
            console.log('unsetPartner => ',response);
            if(response.data[0].retorno==200){
              this.profile.status.value = false;
              this.profile.buzzer = null;
              this.profile.status.value = response.data[0].status_driver;

              this.$emit('updated', this.profile);

              this.toggleToast(
                true,
                'Parceria encerrada sucesso',
                5000,
                false,
                'success'
              );
            }else if(response.data[0].retorno==401){
              this.logout(username,token);
              this.handleError(error, 'Sua sessão expirou...');
            }else{
              this.handleError(response.data.retorno, 'Erro desconhecido. Vamos investigar o que houve! 😊');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            console.log(false, 'driver.unsetPartner');
            this.partnerBtn.loading = false;
          });
      },

    },

    mounted () {

      // load cities
      const c = _.map(cities, city => {
        return {
          title: city.Nome,
          group: 'Outras regiões'
        }
      });
      // console.log('cities', c);
      this.fields.job.region.items = _.concat(this.fields.job.region.items, c);
    },

    directives: {
      mask,
    }
  }
</script>
