<template>
  <m-field
    v-model="value"
    :label="label"
    :disabled="loading||disabled"
    :loading="loading"
    light
    outlined
    class="player-config"
  >
    <div 
      class="list"
    >
      <div 
        v-for="(config, c) in list"
        :key="'player-config-'+c"
        class="config d-flex align-center px-3 py-2"
      >
        <v-menu
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              size="32"
              v-on="on"
              v-bind="attrs"
              class="mr-4"
            >
              <v-icon 
                v-if="!config.driver"
                size="24"
                v-html="icons.edit"
              />
              <v-img 
                v-else-if="!!controller.drivers[config.driver]?.photo"
                :src="controller.drivers[config.driver].photo" 
              />
              <icon-base
                v-else
                width="96"
                height="96"
                icon-name="avatar"
                class="avatar-default"
              >
                <avatar-default />
              </icon-base>
            </v-avatar>
          </template>
          <v-list
            dense
          >
            <v-list-item-group
              v-model="config.driver"
            >
              <v-list-item
                :value="null"
                @click="update(config.id, { driver: null })"
              >
                <v-list-item-avatar size="24">
                  <v-icon 
                    size="24"
                    v-html="icons.edit"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    Padrão
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="driver in controller.drivers"
                :key="driver.id"
                :value="driver.partner_id"
                @click="update(config.id, { driver: driver.partner_id })"
              >
                <v-list-item-avatar size="24">
                  <v-img 
                    v-if="driver.photo"
                    :src="driver.photo" 
                  />
                  <icon-base
                    v-else
                    width="96"
                    height="96"
                    icon-name="avatar"
                    class="avatar-default"
                  >
                    <avatar-default />
                  </icon-base>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ driver.fullname }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-icon v-if="breakpoint('xs')" small>{{ icons.clock }}</v-icon>
        <v-text-field
          v-model="config.start"
          solo
          flat
          type="time"
          hide-details
          class="start"
          @input="change"
        />
        –
        <v-text-field
          v-model="config.end"
          solo
          flat
          type="time"
          hide-details
          class="end"
          @input="change"
        />
        <v-divider vertical class="ml-1 mr-3" />
        <v-icon small>{{ icons.brightness }}</v-icon>
        <v-text-field
          v-model="config.brightness"
          solo
          flat
          type="number"
          min="-100"
          max="100"
          hide-details
          class="brightness"
          @input="change"
        />
        <v-divider vertical class="ml-1 mr-3" />
        <v-icon small>{{ icons.contrast }}</v-icon>
        <v-text-field
          v-model="config.contrast"
          solo
          flat
          type="number"
          min="-100"
          max="100"
          hide-details
          class="contrast"
          @input="change"
        />
        <v-btn
          fab
          absolute 
          small
          class="btn remove"
          @click="remove(config.id)"
        >
          <v-icon small>{{ icons.remove }}</v-icon>
        </v-btn>
      </div>
    </div>
    <v-btn
      text
      color="grey"
      class="ma-2"
      @click="add()"
    >
      <v-icon left>
        {{ icons.plus }}
      </v-icon>
      Adicionar configuração
    </v-btn>
  </m-field>
</template>

<style scoped>
  .player-config {
    /* min-height: 52px; */
  }
  .player-config .config {
    position: relative;
    border-bottom: 1px solid var(--light-border);
  }
  .player-config .config .btn.remove {
    left: auto;
    right: -.75rem
  }
  .player-config .config .start, .player-config .config .end {
    max-width: 70px;
  }
</style>

<script>
  import services from '@/services'
  import moment from 'moment';

  export default {
    data: () => ({
      controller: {
        value: null,
        drivers: {}
      }
    }),
    computed: {
      list () {
        const configs = this.controller.value;
        const list = _.filter(configs, config => !config.remove);
        return list;
      }
    },
    watch: {
      value: {
        immediate: true,
        deep: true,
        handler (value) {
          this.controller.value = _.cloneDeep(value);
        }
      },
      drivers (drivers) {
        this.controller.drivers = _.keyBy(_.filter(drivers, driver => driver.acceptedAt!=null&&(driver.declinedAt==null||moment(driver.acceptedAt).isAfter(driver.declinedAt))), 'partner_id');
      }
    },
    methods: {
      ...services,

      add () {
        const value = this.controller.value;
        const start = moment(this.timeMin).format('HH:mm');
        const end = moment(this.timeMax).format('HH:mm');
        const id = Date.now();
        value.push({
          id,
          brightness: 0,
          contrast: 0,
          start,
          end,
          driver: null,
          remove: false
        });
        this.change();
      },

      update (id, data) {
        const config = _.find(this.controller.value, ['id', id]);
        _.each(data, (v, k) => config[k] = v);
        this.change();
      },
      
      remove (id) {
        const config = _.find(this.controller.value, ['id', id]);
        config.remove = true;
        // this.$delete(this.value, i);
        this.change();
      },

      change () {
        this.$emit('change', this.controller.value);
      }
    },

    props: {
      value: {
        type: [Array],
        default: () => []
      },
      drivers: {
        type: [Array],
        default: () => []
      },
      label: {
        type: String,
        default: undefined
      },
      timeMin: {
        type: String,
        default: undefined
      },
      timeMax: {
        type: String,
        default: undefined
      },
      disabled: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      icons: {
        type: Object,
        default: () => {}
      }
    },

    components: {
      MField: () => import('@/components/mField'),
      IconBase: () => import('@/components/IconBase'),
      AvatarDefault: () => import('@/components/icons/AvatarDefault'),
    }
  }
</script>